<template>
  <ValidationObserver ref="contactForm" v-slot="{ invalid }">
    <div class="flex flex-col gap-3 mt-3">
      <!-- Dla niezalogowanych -->
      <FieldWrapper>
        <TextField
          field="email"
          autocomplete
          :placeholder="trans('LABEL:Email')"
          rules="email|required|max:255"
          :disabled="userData !== null"
          v-model="contactData.email"
        />
      </FieldWrapper>
      <!-- Dla zalogowanych -->
      <div class="w-full flex flex-row justify-between items-center gap-3">
        <span class="w-1/3 text-base">
          {{trans('LABEL:ContactReason')}}
        </span>
        <FieldWrapper class="w-2/3">
          <!-- Ogolny formularz kontaktowy -->
          <SelectField v-if="type === 'CONTACT_FORM'"
            rules="required"
            v-model="contactData.type"
             :placeholder="trans('LABEL:Choose')"
             :options="getAdminMessagesTypes"
          />
          <!-- Formularz dedykowany jednemu typowi -->
          <SelectField v-else
            rules="required"
            v-model="type"
             :placeholder="trans('LABEL:Choose')"
            :options="getAdminMessagesTypes"
            disabled
          />
        </FieldWrapper>
      </div>
      <!-- Tresc wiadomosci -->
      <VueTrix
        v-model="contactData.content" type="text" ref="content" id="content"
        :placeholder="trans('PLACEHOLDER:ChatForm')"
        rules="required|min:100|max:3000"
        class="block outline-none trix-modal focus:text-black border border-grayed messages-chat"
        name="content" required autocomplete="off"
    />
    </div>
    <!-- Przycisk wyslij -->
    <div class="flex items-center justify-end mt-3">
      <Button
        type="submit"
        :loading="adminMessageSending"
        @clicked="sendAdminMessageAction"
        :disabled="invalid"
        :text="trans('BUTTON:Send')"
      />
    </div>
  </ValidationObserver>
</template>

<script>
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import TextField from "@/utils/crud/components/field-types/Text";
import SelectField from "@/utils/crud/components/field-types/Select";
import Button from "@/utils/crud/components/Button";
import TextareaField from "@/utils/crud/components/field-types/Textarea";
import {mapActions, mapGetters} from "vuex";
import {isEmpty} from "@/common/helpers/utils";

const userNotLoggedMessagesTypes = [
  'REGISTRATION', 'COOPERATION', 'TECHNICAL',  'OTHER'
];

const userLoggedMessagesTypes = [
  'REGISTRATION', 'VERIFICATION', 'CHANGE', 'VIOLATION', 'BAN', 'COOPERATION', 'TECHNICAL', 'OTHER'
];

export default {
  name: "ContactForm",
  components: {TextareaField, Button, SelectField, TextField, FieldWrapper},
  props: {
    type: String,
    reportedUser: Object,
  },
  data() {
    return {
      contactData: {},
    }
  },
  watch: {
    adminMessageSent(val) {
      if(val) {
        this.contactData.content = null;
        this.contactData.email = null;
        this.contactData.type = null;
        this.$refs.contactForm.reset();
      }
    }
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('contact', ['adminMessageSending', 'adminMessageSent']),
	  ...mapGetters('dictionaries', ['adminMessagesTypes']),

	  getAdminMessagesTypes() {
		  let cutAdminMessages = structuredClone(this.adminMessagesTypes);
		  let adminMessages = [];

		  if (!this.userData) {
			  Object.keys(this.adminMessagesTypes).forEach(el => {
				  if (!userNotLoggedMessagesTypes.includes(el)) {
					  delete cutAdminMessages[el];
				  }
			  });
			  adminMessages = cutAdminMessages;
		  } else {
			  adminMessages = this.adminMessagesTypes;
		  }

		  return Object.fromEntries(
				  Object.entries(adminMessages).map(([key, value]) => [key, this.trans(value)])
		  );
	  }
  },
  methods: {
    ...mapActions('contact', ['sendAdminMessageRequest']),
	  ...mapActions('chat', ['fetchMessageThreadsData']),
    sendAdminMessageAction() {
      let data = {
        content: this.contactData.content,
        type: this.type,
        email: this.contactData.email
      }
      if(this.type === 'CONTACT_FORM') {
        data.type = this.contactData.type
      } else {
        data.type = this.contactData.type
      }
      if(!isEmpty(this.reportedUser)) {
        data.reportedUsername = this.reportedUser.username;
        data.type = this.type;
      }
      this.sendAdminMessageRequest(data);

      this.$emit('send-admin-message');
    },

  },
  mounted() {
    if(this.$route.query.registration) {
      this.contactData.type = 'REGISTRATION';
      this.contactData.email = this.$route.query.email ? this.$route.query.email : null;
    }
	  if(this.$route.query.banned) {
		  this.contactData.type = 'BAN';
		  this.contactData.email = this.userData.email;
	  }
    if(this.userData) {
      this.$set(this.contactData, 'email', this.userData.email)
    }
  }
}
</script>

<style lang="scss">
 trix-toolbar {
	 display: none;
 }

 trix-editor {
	 border-color: #000;
 }

 .trix-content {
	 min-height: 200px;
	 max-height: 200px !important;
	 overflow-y: auto !important;
 }

 .trix-modal {
   border: none;
   padding: 0;

   .trix-content {
     height: 240px;
     max-height: 450px !important;
     overflow-y: auto !important;
     background: theme('colors.grayedLight') !important;
     border:1px solid theme('colors.grayed') !important;
     border-radius: 6px;
     word-break: break-word;
   }
 }
</style>